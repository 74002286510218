import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from '@lib/notifications';
import { AuthorizationApiProvider, ChatApiProviderService } from '@project/data-providers';
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@project/translate';
import { EProfileTypeDTO, IAuthResult } from '@project/view-models';
import { AuthorisationStateService } from '@project/services';
import { QrCodeService } from '../../../project/components/modal-qr-code/qr-code.service';

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

@Component({
  selector: 'app-upload-external-files',
  templateUrl: './upload-external-files.component.html',
  styleUrls: ['./upload-external-files.component.scss'],
})
export class UploadExternalFilesComponent implements OnInit, OnDestroy {
  private chatId: string;
  private authCode: string;
  files: File[] = [];
  public isLoading = true;
  public soslogo = 'assets/logos/colored-logo.svg';
  public piauilogo = 'assets/logos/piaui-logo2.png';
  public isPiaui: boolean;

  private destroy$ = new ReplaySubject(1);

  constructor(
    private chatApiProviderService: ChatApiProviderService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private authorizationApiProvider: AuthorizationApiProvider,
    private authorisationStateService: AuthorisationStateService,
    private qrCodeService: QrCodeService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.chatId = this.route.snapshot.paramMap.get('id') || '';
    this.authCode = this.route.snapshot.queryParamMap.get('code') || '';

    const piauiParam = this.route.snapshot.queryParamMap.get('piaui');
    this.isPiaui = piauiParam === 'true';

    if (this.authorisationStateService.isAuthorized()) {
      this.isLoading = false;
      this.cd.detectChanges();
    } else {
      this.authenticateByCode(this.authCode);
    }
  }

  private authenticateByCode(code: string) {
    const loadingTimeout = setTimeout(() => {
      this.isLoading = false;
      this.cd.detectChanges();
    }, 10000);

    this.authorizationApiProvider.webTokenLogin(code).subscribe({
      next: (authResult: IAuthResult) => {
        clearTimeout(loadingTimeout);
        this.authorisationStateService.setAuthorisationData(authResult);
        this.isLoading = false;
        this.cd.detectChanges();
        console.log(authResult);
      },
      error: (e) => {
        if (e?.status === 409) {
          const token = e.error.token;
          const healthCenterId = e.error.profiles[0].health_centers[0];

          this.handleTitular(token, healthCenterId);
        } else if (e?.status === 404) {
          console.log('ERRO 404', e);
        } else {
          clearTimeout(loadingTimeout);
          const errorMessage = this.qrCodeService.getErrorMessage(e.status);
          this.notificationsService.error({
            message: errorMessage,
          });
        }
      },
    });
  }

  private handleTitular(token: string, healthCenterId: string) {
    const totalDependents = 1;
    const roles = EProfileTypeDTO.Patient;

    this.authorizationApiProvider.select(roles, healthCenterId, token, null, totalDependents).subscribe((value) => {
      this.authorisationStateService.setAuthorisationData(value);
      this.isLoading = false;
      this.cd.detectChanges();
      console.log(value);
      return value;
    });
  }

  triggerFileInput() {
    document.getElementById('fileInput')?.click();
  }

  openCamera() {
    document.getElementById('photoInput')?.click();
  }

  SendFiles() {
    if (this.files.length > 0 && this.chatId) {
      let filesUploaded = 0;
      let filesWithError = 0;

      this.files.forEach((file) => {
        if (file.size <= MAX_FILE_SIZE_BYTES) {
          this.chatApiProviderService.uploadFile(this.chatId, file).subscribe(
            () => {
              filesUploaded++;
              if (filesUploaded + filesWithError === this.files.length) {
                this.notificationsService.success({
                  message: TranslateService.localize('files.sent.succesfully'),
                });
                this.clearFiles();
              }
            },
            (error) => {
              filesWithError++;
              this.notificationsService.error({
                message: TranslateService.localize('error.sending.files', error),
              });
              if (filesUploaded + filesWithError === this.files.length) {
                this.clearFiles();
              }
            },
          );
        } else {
          filesWithError++;
        }
      });

      if (filesWithError === this.files.length) {
        this.clearFiles();
      }
    }
  }

  handlePhotoInput(event) {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      Array.from(files).forEach((file) => {
        this.addFileToContainer(file);
      });
    }
  }

  addFileToContainer(file: File) {
    const filesContainer = document.querySelector('.upload-external-files-container') as HTMLElement;
    const confirmContainer = document.querySelector('.confirm-upload-external-files-container') as HTMLElement;

    this.files.push(file);
    filesContainer.style.display = 'flex';

    if (file.size > MAX_FILE_SIZE_BYTES) {
      this.handleFileSizeExceeded(file, filesContainer);
    } else {
      this.handleValidFile(file, filesContainer, confirmContainer);
    }

    this.addRemoveFileListeners();
  }

  private createElement(html: string): HTMLElement {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.firstElementChild as HTMLElement;
  }

  private handleFileSizeExceeded(file: File, filesContainer: HTMLElement) {
    const errorHtml = `
        <div class="upload-external-files-error-box">
            <div class="upload-external-file-info">
                <p>${file.name}</p>
                <div class="upload-external-files-icon-button">
                    <i class="fas fa-times-circle" style="color: red;"></i>
                    <button class="upload-external-files-remove-button" data-filename="${file.name}">X</button>
                </div>
            </div>
            <hr>
            <div class="upload-external-file-message">${TranslateService.localize('file.size.exceeded')}</div>
        </div>`;
    filesContainer?.appendChild(this.createElement(errorHtml));
  }

  private handleValidFile(file: File, filesContainer: HTMLElement, confirmContainer: HTMLElement) {
    const fileHtml = `
        <div class="upload-external-file-box">
            <p>${file.name}</p>
            <div class="upload-external-files-icon-button">
                <i class="fas fa-spinner fa-spin" style="color: gray;"></i>
                <button class="upload-external-files-remove-button" data-filename="${file.name}">X</button>
            </div>
        </div>`;
    filesContainer?.appendChild(this.createElement(fileHtml));

    this.handleSpinner(filesContainer);

    confirmContainer.style.display = 'flex';
  }

  private handleSpinner(filesContainer: HTMLElement) {
    setTimeout(() => {
      const spinner = filesContainer.querySelector('.fa-spinner') as HTMLElement;
      if (spinner) {
        spinner.classList.remove('fa-spinner', 'fa-spin');
        spinner.classList.add('fa-check-circle');
        spinner.style.color = 'green';
      }
    }, 2000);
  }

  addRemoveFileListeners() {
    const removeButtons = document.querySelectorAll('.upload-external-files-remove-button');
    removeButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        const fileName = (event.target as HTMLButtonElement).dataset.filename || '';
        this.removeFile(fileName);
      });
    });
  }

  handleFileInput(event) {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      Array.from(files).forEach((file) => {
        this.addFileToContainer(file);
      });
    }
  }

  removeFile(fileName: string) {
    const filesContainer = document.querySelector('.upload-external-files-container') as HTMLElement;
    const confirmContainer = document.querySelector('.confirm-upload-external-files-container') as HTMLElement;

    if (!filesContainer) {
      this.notificationsService.error({ message: TranslateService.localize('file.not.found') });
      return;
    }
    this.files = this.files.filter((file) => file.name !== fileName);

    const fileBox = Array.from(filesContainer.getElementsByClassName('upload-external-file-box')).find(
      (box) => box.querySelector('p')?.textContent === fileName,
    ) as HTMLElement;

    if (fileBox) {
      fileBox.remove();
    }
    const errorBox = Array.from(filesContainer.getElementsByClassName('upload-external-files-error-box')).find(
      (box) => box.querySelector('p')?.textContent === fileName,
    ) as HTMLElement;

    if (errorBox) {
      errorBox.remove();
    }

    const anyFileVisible = filesContainer.querySelector('.upload-external-file-box') !== null;
    const anyErrorVisible = filesContainer.querySelector('.upload-external-files-error-box') !== null;

    if (!anyFileVisible && !anyErrorVisible) {
      if (filesContainer instanceof HTMLElement) {
        filesContainer.style.display = 'none';
      }

      if (confirmContainer instanceof HTMLElement) {
        confirmContainer.style.display = 'none';
      }
    }
  }

  clearFiles() {
    const filesContainer = document.querySelector('.upload-external-files-container') as HTMLElement;
    const confirmContainer = document.querySelector('.confirm-upload-external-files-container') as HTMLElement;

    if (filesContainer) {
      filesContainer.innerHTML = '';
    }
    if (confirmContainer) {
      confirmContainer.style.display = 'none';
    }

    this.files = [];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
