import * as amplitude from '@amplitude/analytics-browser';
import { Identify } from '@amplitude/analytics-browser';
import { socketMonitor } from './socket-monitor';
import { httpMonitor } from './http-monitor';
import { appMonitor } from './app-monitor';
import { screenMonitor } from './screen-monitor';
import { environment } from '@env';

export const monitorLogger = (() => {
  const logEvent = async (eventName: string, props?: any) => {
    if (props && typeof props === 'object') {
      amplitude.logEvent(eventName, props);
      showLog(eventName, props);
    } else {
      amplitude.logEvent(eventName);
      showLog(eventName);
    }
  };

  const showLog = (eventName: string, eventProps?: any) => {
    if (!environment.production) {
      if (eventProps) {
        console.log(eventName, eventProps);
      } else {
        console.log(eventName);
      }
    }
  };

  const trackEventHttp = (method: string, status: number, url: string, props?: any) => {
    logEvent(`[HTTP]${status ? `[${status}]` : `[${method?.toUpperCase()}]`} ${url}`, props);
  };

  const trackEventApp = (eventName: string, props?: any) => {
    logEvent(`[APP]${eventName}`, props);
  };

  const trackEventSocket = (eventName: string, props?: any) => {
    logEvent(`[SOCKET][${eventName}]`, props);
  };

  const trackEventScreen = (screenName: string, eventName: string, props?: any) => {
    logEvent(`[SCREEN][${screenName}] ${eventName.toLowerCase()}`, props);
  };

  const identifyProfile = (profileId: string, email: string, cpf: string, gender: string, profileType: string) => {
    const identify = new Identify();
    identify.set('id', profileId);
    identify.set('email', email);
    identify.set('cpf', cpf);
    identify.set('gender', gender);
    identify.set('profileType', profileType);
    amplitude.identify(identify);
    amplitude.setUserId(email);
  };

  const clearProfile = async () => {
    amplitude.reset();
  };

  const initializeAnalytics = (env: string) => {
    amplitude.setGroup('environment', env);
    amplitude.setOptOut(false);
  };

  return {
    initializeAnalytics,
    identifyProfile,
    clearProfile,
    socket: socketMonitor(trackEventSocket),
    http: httpMonitor(trackEventHttp),
    app: appMonitor(trackEventApp),
    screen: screenMonitor(trackEventScreen),
  };
})();
