import { Injectable } from '@angular/core';
// TODO This shouldn't be here but it's ok for now
import { TRouteWithOutlet, DEFAULT_ASIDE_OUTLET, EAsideOutlets } from '@project/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { TGuid } from '@core/helpers';
import { IRequisition } from '@project/view-models';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class RoutesBuilderService {
  static ASIDE_NAVIGATION = {
    asideRouteBuilder(params: { routes: string[]; outlet?: EAsideOutlets; relativeRoute?: string }): TRouteWithOutlet {
      const result = [];
      if (params?.relativeRoute) {
        result.push(params?.relativeRoute);
      }
      return result.concat([{ outlets: { [params.outlet || DEFAULT_ASIDE_OUTLET]: params.routes } }]);
    },
    asideRoute(params: { route: string | string[]; outlet?: EAsideOutlets; relativeRoute?: string }): TRouteWithOutlet {
      return this.asideRouteBuilder({
        routes: typeof params.route === 'string' ? [params.route] : params.route,
        outlet: params.outlet || DEFAULT_ASIDE_OUTLET,
        relativeRoute: params.relativeRoute,
      });
    },
    buildAsideRoute(relativeRoute: string, path: string | string[]) {
      return [relativeRoute, { outlets: { [DEFAULT_ASIDE_OUTLET]: path } }];
    },
    asideChat(requisitionId: TGuid): TRouteWithOutlet {
      return this.asideRouteBuilder({
        routes: [
          RoutesBuilderService.SESSION_DETAILS.base,
          requisitionId,
          RoutesBuilderService.SESSION_DETAILS.baseChat,
        ],
        outlet: EAsideOutlets.GlobalAsideOutlet,
        relativeRoute: '/',
      });
    },
    asideRequisitionDetails(requisitionId: TGuid): TRouteWithOutlet {
      return this.asideRouteBuilder({
        routes: [RoutesBuilderService.SESSION_DETAILS.base, requisitionId],
        outlet: EAsideOutlets.GlobalAsideOutlet,
        relativeRoute: '/',
      });
    },
    asideRequisitionDetailsChat(requisitionId: TGuid): TRouteWithOutlet {
      return this.asideRouteBuilder({
        routes: [
          RoutesBuilderService.SESSION_DETAILS.base,
          requisitionId,
          RoutesBuilderService.SESSION_DETAILS.baseChat,
        ],
        outlet: EAsideOutlets.GlobalAsideOutlet,
        relativeRoute: '/',
      });
    },
    asideRequisitionDetailsHistory(requisitionId: TGuid): TRouteWithOutlet {
      return this.asideRouteBuilder({
        routes: [
          RoutesBuilderService.SESSION_DETAILS.base,
          requisitionId,
          RoutesBuilderService.SESSION_DETAILS.baseHistory,
        ],
        outlet: EAsideOutlets.GlobalAsideOutlet,
        relativeRoute: '/',
      });
    },
  };

  static AUTH = {
    base: 'auth',
    baseLogin: 'login',
    baseLinkSocial: 'link-social',
    baseForgot: 'forgot',
    baseFirst: 'first',
    baseFirstSucess: 'first-success',
    baseFirstNotFound: 'first-not-found',
    baseReset: 'reset',
    baseSuccessfulReset: 'successful-reset',
    baseSignUp: 'sign-up',
    baseSelectRole: 'select-role',
    baseConfirm: 'confirm-user',
    baseImportPatient: 'test-import-patient',
    baseAccessBlocked: 'access-blocked',
    login(): string {
      return `/${this.base}/${this.baseLogin}`;
    },
    signUp(): string {
      return `/${this.base}/${this.baseSignUp}`;
    },
    linkSocial(): string {
      return `/${this.base}/${this.baseLinkSocial}`;
    },
    forgotPassword(): string {
      return `/${this.base}/${this.baseForgot}`;
    },
    firstSignin(): string {
      return `/${this.base}/${this.baseFirst}`;
    },
    firstSigninSuccess(): string {
      return `/${this.base}/${this.baseFirstSucess}`;
    },
    firstSigninNotFound(): string {
      return `/${this.base}/${this.baseFirstNotFound}`;
    },
    forgotPasswordRelativeRoute(): string {
      return `${this.base}/${this.baseForgot}`;
    },
    resetPasswordRelativeRoute(): string {
      return `${this.base}/${this.baseReset}`;
    },
    successfulResetPasswordRelativeRoute(): string {
      return `${this.base}/${this.baseSuccessfulReset}`;
    },
    selectRole(): string {
      return `${this.base}/${this.baseSelectRole}`;
    },
    confirmRelativeRoute(): string {
      /**
       * IMPORTANT: Used as relative instead of absolute to have ability to redirect with query params
       */
      return `${this.base}/${this.baseConfirm}`;
    },
    testImportPatient(): string {
      return `/${this.base}/${this.baseImportPatient}`;
    },
  };

  static FIRST_ACCESS = {
    base: 'first-access',
    piauiBase: 'piaui',
    piaui(): string {
      return `/${this.base}/${this.piauiBase}`;
    },
  };

  static EXTERNAL_FILES_ACCESS = {
    base: 'external-files-access',
    piauiBase: 'piaui',
    piaui(): string {
      return `/${this.base}/${this.piauiBase}`;
    },
  };

  static DATA_DELETION = {
    base: 'data-deletion',
    sosPortalBase: 'sosPortal',
    sosPortal(): string {
      return `/${this.base}/${this.sosPortalBase}`;
    },
    piauiBase: 'piaui',
    piaui(): string {
      return `/${this.base}/${this.piauiBase}`;
    },
  };

  static LOGOUT = {
    base: 'logout',
    logout(): string {
      return `/${this.base}`;
    },
  };

  static SESSION_LOGS = {
    base: 'session-logs',
    root(): string {
      return `/${this.base}`;
    },
    toPage(page: number): string {
      return `/${this.base}/${page}`;
    },
    asideChatBase: 'chat',
    asideNotesBase: 'notes',
    asideQuestionnaireBase: 'questionnaire',
    asideObservationsBase: 'observations',
    asideHistoryStatus: 'history-status',
  };

  static LOGINS_LOGS = {
    base: 'login-logs',
    root(): string {
      return `/${this.base}`;
    },
    toPage(page: number): string {
      return `/${this.base}/${page}`;
    },
  };

  static ENCOUNTER_HISTORY_AS_PATIENT = {
    base: 'patient-encounter-history',
    root(): string {
      return `/${this.base}`;
    },
    toPage(page: number): string {
      return `/${this.base}/${page}`;
    },
    asideChatBase: 'chat',
    asideNotesBase: 'notes',
    asideQuestionnaireBase: 'questionnaire',
    asideObservationsBase: 'observations',
    asidePrescriptionsBase: 'prescriptions',
  };

  static ENCOUNTER_HISTORY_AS_DOCTOR = {
    base: 'doctor-encounter-history',
    root(): string {
      return `/${this.base}`;
    },
    toPage(page: number): string {
      return `/${this.base}/${page}`;
    },
    asideChatBase: 'chat',
    asideNotesBase: 'notes',
    asideQuestionnaireBase: 'questionnaire',
    asideObservationsBase: 'observations',
    asideSummaryBase: 'summary',
    asideMedicalRecord: 'medical-record',
  };

  static ENCOUNTER_HISTORY_AS_RECEPCIONIST = {
    base: 'recepcionist-encounter-history',
    root(): string {
      return `/${this.base}`;
    },
    toPage(page: number): string {
      return `/${this.base}/${page}`;
    },
    asideChatBase: 'chat',
    asideNotesBase: 'notes',
    asideQuestionnaireBase: 'questionnaire',
    asideObservationsBase: 'observations',
    asideSummaryBase: 'summary',
    asideMedicalRecord: 'medical-record',
    asidePrescriptionsBase: 'prescriptions',
    asideForms: 'forms',
  };

  static GUIDES_HISTORY_AS_RECEPTIONIST = {
    base: 'receptionist-forms-history',
    root(): string {
      return `/${this.base}`;
    },
    toPage(page: number): string {
      return `/${this.base}/${page}`;
    },
    asideChatBase: 'chat',
    asideNotesBase: 'notes',
    asideQuestionnaireBase: 'questionnaire',
    asideObservationsBase: 'observations',
    asideSummaryBase: 'summary',
    asideMedicalRecord: 'medical-record',
    asidePrescriptionsBase: 'prescriptions',
  };

  static ONBOARDING = {
    base: 'onboarding',
    baseSetPassword: 'set-password',
    baseFillPersonalData: 'fill-personal',
    basePatientData: 'patient',
    fillPatientPersonalData(): string {
      return `/${this.base}/${this.baseFillPersonalData}/${this.basePatientData}`;
    },
    setPassword(): string {
      return `/${this.base}/${this.baseSetPassword}`;
    },
  };

  static DOCTORS_LIST = {
    base: 'personnel',
    baseList: 'list',
    baseInvitations: 'invited',
    invite: 'invite',
    edit: 'edit',
    asideDetails: 'details',
    asideNotesBase: 'notes',
    root(): string {
      return `/${this.base}`;
    },
    list(): string {
      return `${this.root()}/${this.baseList}`;
    },
    invitationsList(): string {
      return `${this.root()}/${this.baseInvitations}`;
    },
    summaryNotes(doctorId: TGuid, reqId: TGuid): string {
      return `${doctorId}/notes/${reqId}`;
    },
  };

  static SESSION_DETAILS = {
    base: 'session-details',
    baseHistory: 'history',
    baseSummary: 'summary',
    baseGenerateApac: 'apac',
    baseGenerateSADT: 'sadt',
    baseChat: 'chat',
    baseObservations: 'observations',
    baseQuestionnaire: 'questionnaire',
  };

  static RECEPTIONIST_SESSION_DETAILS = {
    baseHistory: 'history',
    baseSummary: 'summary',
    baseChat: 'chat',
    baseAssign: 'assign',
    baseQuestionnaire: 'questionnaire',
  };

  static SESSION_BY_DOCTOR = {
    baseAppointmentsSessions: 'appointments',
    baseLiveSessions: 'live',
    baseMainAppointments: 'main',
    baseMainLive: 'main',
    baseSchedule: 'schedule',
    liveSessions(): string {
      return `${RoutesBuilderService.SESSIONS.specialistSessions()}/${this.baseLiveSessions}`;
    },
    appointmentsSessions(): string {
      return `${RoutesBuilderService.SESSIONS.specialistSessions()}/${this.baseAppointmentsSessions}`;
    },
  };

  static SESSION_BY_PATIENT = {
    baseMain: 'main',
    baseRequestDoctor: 'request',
    workspace(): string {
      return `${RoutesBuilderService.SESSIONS.patientSessions()}/${this.baseMain}`;
    },
    requestDoctor(): string {
      return `${RoutesBuilderService.SESSIONS.patientSessions()}/${this.baseRequestDoctor}`;
    },
  };

  static SESSIONS = {
    base: 'sessions',
    baseSpecialistPart: 'specialist',
    basePatientPart: 'patient',
    root(): string {
      return `/${this.base}`;
    },
    specialistSessions(): string {
      return `${this.root()}/${this.baseSpecialistPart}`;
    },
    patientSessions(): string {
      return `${this.root()}/${this.basePatientPart}`;
    },
  };

  static ADMINISTRATORS_LIST = {
    base: 'administrators',
    invite: 'invite',
    baseInvitations: 'invited',
    baseList: 'list',
    root(): string {
      return `/${this.base}`;
    },
    listGeneral(): string {
      return `${this.root()}/${this.baseList}`;
    },
    invitationsList(): string {
      return `${this.root()}/${this.baseInvitations}`;
    },
  };

  static HEALTH_CENTERS_ADMINISTRATORS_LIST = {
    base: 'hc-admins',
    baseList: 'list',
    baseInvitations: 'invited',
    invite: 'invite',
    edit: 'edit',
    root(): string {
      return `/${this.base}`;
    },
    list(): string {
      return `${this.root()}/${this.baseList}`;
    },
    invitationsList(): string {
      return `${this.root()}/${this.baseInvitations}`;
    },
  };

  static HEALTH_CENTERS_LIST = {
    base: 'health-centers',
    root(): string {
      return `/${this.base}`;
    },
  };

  static OBSERVATIONS = {
    base: 'observations',
    baseList: 'list',
    root(): string {
      return `/${this.base}`;
    },
    list(): string {
      return `${this.root()}/${this.baseList}`;
    },
  };

  static MY_OBSERVATIONS_AS_PATIENT = {
    base: 'patient-observations',
    baseActiveList: 'active',
    baseArchiveList: 'archive',
    root(): string {
      return `/${this.base}`;
    },
    activeList(): string {
      return `${this.root()}/${this.baseActiveList}`;
    },
    archiveList(): string {
      return `${this.root()}/${this.baseArchiveList}`;
    },
  };

  static DOCTOR_REQUEST = {
    base: 'questionnaire',
  };

  static PATIENTS_LIST = {
    base: 'patients',
    baseList: 'list',
    baseInvitations: 'invitations',
    invite: 'invite',
    dependent: 'dependent',
    edit: 'edit',
    root(): string {
      return `/${this.base}`;
    },
    list(): string {
      return `${this.root()}/${this.baseList}`;
    },
    invitations(): string {
      return `${this.root()}/${this.baseInvitations}`;
    },
  };

  static SENDGRID = {
    base: 'sendgrid',
    bounces: 'bounces',
    spams: 'spams',
    invalids: 'invalids',
    blocks: 'blocks',
    root(): string {
      return `/${this.base}`;
    },
    bounce(): string {
      return `${this.root()}/${this.bounces}`;
    },
    spam(): string {
      return `${this.root()}/${this.spams}`;
    },
    invalid(): string {
      return `${this.root()}/${this.invalids}`;
    },
    block(): string {
      return `${this.root()}/${this.blocks}`;
    },
  };

  static SETTINGS = {
    base: 'settings',
    root(): string {
      return `/${this.base}`;
    },
  };

  static HEALTH_CENTER_SETTINGS = {
    base: 'hc-settings',
    baseAsideBinnahSettings: 'binnah-settings',
    root(): string {
      return `/${this.base}`;
    },
  };

  static RECEPTIONISTS_LIST = {
    base: 'receptionists',
    baseList: 'list',
    baseInvitations: 'invitations',
    invite: 'invite',
    edit: 'edit',
    root(): string {
      return `/${this.base}`;
    },
    list(): string {
      return `${this.root()}/${this.baseList}`;
    },
    invitations(): string {
      return `${this.root()}/${this.baseInvitations}`;
    },
  };

  static SESSION_BY_RECEPTIONIST = {
    base: 'receptionist-sessions',
    baseQueue: 'queue',
    invite: 'invite',
    baseScheduling: 'scheduling',
    root(): string {
      return `/${this.base}`;
    },
    queue(): string {
      return `${this.root()}/${this.baseQueue}`;
    },
    scheduling(): string {
      return `${this.root()}/${this.baseScheduling}`;
    },
  };

  static WORKING_HOURS = {
    base: 'working-hours',
    basePersonnel: 'personnel',
    baseAsideDetailsWorkingHours: 'working-hours',
    baseAsideDetailsAvailability: 'availability',
    root(): string {
      return `/${this.base}`;
    },
    listPersonnel(): string {
      return `${this.root()}/${this.basePersonnel}`;
    },
  };

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  public closeAsideRoute(relativeToRoute: ActivatedRoute = this.activatedRoute, outlet: EAsideOutlets) {
    const activatedRouteWithAsideOutlet = this.resolveChildActivatedRouteWithSecondaryOutlet(relativeToRoute, outlet);
    if (!activatedRouteWithAsideOutlet) {
      console.warn('Aside outlet children not found');
      return;
    }

    this.router.navigate([{ outlets: { [outlet]: null } }], {
      relativeTo: activatedRouteWithAsideOutlet,
      queryParams: relativeToRoute.snapshot.queryParams,
    });
  }

  public getUploadExternalFilesUrl(baseURL: string): string {
    return baseURL;
  }

  private resolveChildActivatedRouteWithSecondaryOutlet(
    root: ActivatedRoute,
    outletName: string,
  ): ActivatedRoute | null {
    let nextActivatedRoute: ActivatedRoute = root;
    while (true) {
      if (nextActivatedRoute.children.find((activatedChildRoute) => activatedChildRoute.outlet === outletName)) {
        return nextActivatedRoute;
      }

      const child = nextActivatedRoute.children.find((activatedChildRoute) => activatedChildRoute.outlet === 'primary');
      if (!child) {
        return null;
      }
      nextActivatedRoute = child;
    }
  }
}
