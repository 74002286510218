import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageLoadProgressModule } from '@lib/page-load-progress';
import { NotificationsModule } from '@lib/notifications';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AsideOutletModule, LayoutModule, SocialAuthorizationModule } from '@modules';
import { environment } from '@env';
import { ReactiveFormsModule } from '@angular/forms';
import { getCurrentLocale, initLocale } from '@project/translate';
import {
  GlobalErrorHandlerService,
  HttpAuthInterceptorService,
  HttpClientIdInterceptorService,
  HttpClientInterceptorService,
  HttpNgswBypassInterceptorService,
  HttpTimezoneInterceptorService,
  HttpInfoDeviceInterceptorService,
} from '@project/services';
import { ModalModule } from '@lib/modal';
import { AuthorizedUserFeaturesContainerModule } from '@features/container';
import { UserInformationCenterManagerService } from './lazy-modules/user-information-center/user-information-center-manager.service';
import { HttpPlatformInterceptorService } from './project/services/interceptors/http-platform-interceptor.service';
import { HttpVersionInterceptorService } from './project/services/interceptors/http-version-interceptor.service';
import { UploadExternalFilesModule } from './lazy-modules/upload-external-files/upload-external-files.module';

initLocale();

const INTERCEPTORS_PROVIDERS: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpAuthInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpClientInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTimezoneInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpNgswBypassInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpClientIdInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInfoDeviceInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpPlatformInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpVersionInterceptorService,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    UploadExternalFilesModule,
    HttpClientModule,
    PageLoadProgressModule,
    NotificationsModule,
    AppRoutingModule,
    LayoutModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: !environment.serveMode }),
    SocialAuthorizationModule.forRoot(),
    ReactiveFormsModule, // For modal overlays forms
    AsideOutletModule,
    ModalModule,
    AuthorizedUserFeaturesContainerModule,
  ],
  providers: [
    INTERCEPTORS_PROVIDERS,
    {
      /**
       * For usage in CookieService.
       */
      provide: 'req',
      useValue: null,
    },
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandlerService,
    },
    { provide: LOCALE_ID, useValue: getCurrentLocale() },
    UserInformationCenterManagerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
