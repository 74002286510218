import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class HcService {
  constructor() {}

  setHealthCenterId(healthCenterId: string): void {
    if (healthCenterId) {
      localStorage.setItem('healthCenterId', healthCenterId);
    } else {
      localStorage.removeItem('healthCenterId');
    }
  }

  getHealthCenterId(): string | null {
    return localStorage.getItem('healthCenterId');
  }

  isPiaui(): boolean {
    const healthCenterId = this.getHealthCenterId();
    return healthCenterId === environment.environmentVariables.healthCenterIdPiaui;
  }

  setPiauiHealthCenter(): void {
    this.setHealthCenterId(environment.environmentVariables.healthCenterIdPiaui);
  }

  setNotDefinedYetHealthCenter(): void {
    this.setHealthCenterId('');
  }
}
